
html, 
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.container{
  margin: 80px auto 0px auto;
  
}

.button-basic{
  padding: 5px;
}


h4{
  margin: 7px
}

p {
  margin: 3px
}