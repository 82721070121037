.texto-center{
    text-align: center;
}

.avatar{
    margin: auto;
    color: blue;
    
}
.container-form{
    margin-top: 130px;
}