.h2bar {
    margin: 10px;
    
}

.divbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ceas {
    display: none;
}

.ceaslong {
    text-align: right;
}


@media (max-width: 515px) {
    
    .ceas {
        display: contents;
        margin-right: 5px;
    }
    .ceaslong {
        display: none;
    }
    .h2bar {
        margin: 5px;
        
    }
    .divbar{
        justify-content: space-around;
    }
}